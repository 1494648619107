import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import styles from './container.module.scss'

interface ContainerProps extends PropsWithChildren {
  tag?: string
  id?: string
  className?: ClassValue,
  collapsed?: boolean
}

const Container: FunctionComponent<ContainerProps> = (
  {
    tag,
    children,
    className,
    id,
    collapsed
  }
) => {
  const Tag = tag || 'main'

  return <Tag
    id={id}
    className={clsx(
      styles.container,
      collapsed && styles.collapsed,
      className
    )}
  >
    {children}
  </Tag>
}

export default Container

export type {
  ContainerProps
}
