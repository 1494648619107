import type { FunctionComponent, ReactElement } from 'react'

import clsx from 'clsx'

import Button, { ButtonVariant } from '../../molecules/button'
import { getActiveChild } from '../navigation'

import styles from './navigation-simple.module.scss'

type NavigationSimpleItem = {
  label?: string
  title?: string
  href?: string
  isActive?: boolean
  component?: ReactElement
}

interface NavigationProps {
  items: NavigationSimpleItem[]
  tag?: string
  ariaLabel?: string
}

const NavigationSimple: FunctionComponent<NavigationProps> = ({
  items = [],
  tag = 'div',
  ariaLabel
}) => {
  const Tag = tag

  return (
    <Tag className={styles.navigation} aria-label={ariaLabel}>
      {
        items.map(item => {
          return <div
              key={`${item.label}-${item.href}`}
              className={clsx(
                styles.navigationItem,
                item.isActive && styles.isActive
              )}
            >
              {
                item.component
                  ? item.component
                  : <Button
                    href={item.href}
                    variant={ButtonVariant.tertiary}
                    aria-current={item.isActive && !getActiveChild(item) && 'page'}
                  >{item.label || item.title}</Button>
              }
            </div>
          }
        )
      }
    </Tag>
  )
}

export {
  NavigationSimple
}

export type {
  NavigationSimpleItem,
  NavigationProps
}
