enum VotingLayout {
  Default = 'default',
  Eddies = 'eddies',
  IedereenBondscoach = 'iedereen-bondscoach',
  VlaamseSportjuwelen = 'vlaamse-sportjuwelen'
}

enum VotingPhase {
  landing = 'landing',
  countdown = 'countdown',
  voting = 'voting',
  results = 'results',
}

export {
  VotingLayout,
  VotingPhase
}
