import type { FunctionComponent } from 'react'
import type { TeamnameProps } from '../../../../molecules/teamname'
import type { ScoreboardLayoutTypes } from '../../index'
import type { ScoreboardScoreProps } from '../default'

import { linkClickEvent } from '@sporza/tracking/events/link-click'
import clsx from 'clsx'

import Caption from '../../../../atoms/caption'
import Button, { ButtonProps, ButtonSize, ButtonVariant } from '../../../../molecules/button'
import FieldTimeline from '../../../../molecules/field-timeline/field-timeline'
import Score, { ScoreLayoutTypes, StatusTypes } from '../../../../molecules/score'
import Teamname, { TeamnameSize } from '../../../../molecules/teamname'
import Table from '../../../table'
import styles from '../../scoreboard.module.scss'

const DetailedScoreBoard: FunctionComponent<ScoreboardScoreProps> = (
  {
    sport,
    status,
    label,
    home,
    away,
    score,
    showLogos,
    showLinks,
    eventSets,
    goals,
    meta,
    relatedMatch,
    designSystemBaseUrl,
    channels = []
  }
) => {
  const Tag = relatedMatch?.componentProps.url ? 'a' : 'div'
  const filteredChannels = channels.filter((channel: any) =>
    !channel.isHiddenFromMatchDetail
    && channel.iconBefore !== 'channels-sporza-livestream'
  )

  return <>
    <div className={clsx(
      styles.scoreboardContent
    )}>
      <Teamname
        size={TeamnameSize.XLarge}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={home as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={showLinks}
        logoPosition='top'
      />

      <Score layout={ScoreLayoutTypes.Detailed}
        score={score}
        sport={sport}
        status={status}
        label={label}
      >
        {
          filteredChannels?.length
            ? <div className={clsx(styles.channels, styles.channelsBig)}>
              {
                status == 'END'
                  ? <Caption key="herbekijk-via">herbekijk</Caption>
                  : <Caption key="livestream-via">kijk live</Caption>
              }
              <div className={styles.channelsList}>
                {
                  filteredChannels
                    .map((channel: ButtonProps) => {
                      const isPlay6 = channel.iconBefore && channel.iconBefore.indexOf('play-6') > -1
                      const isPlaySports = channel.iconBefore && channel.iconBefore.indexOf('play-sports') > -1
                      const isCanvas = channel.iconBefore && channel.iconBefore.indexOf('canvas') > -1
                      const targetUrl = channel.href ? new URL(channel.href) : undefined

                      let iconBefore = channel.iconBefore

                      if (isPlay6) {
                        iconBefore = 'channels-play-6-mono-big'
                      } else if (isPlaySports) {
                        iconBefore = channel.iconBefore + '-big'
                      }

                      return <Button
                        {...channel}
                        target={channel.href && channel.href.indexOf('sporza.be') > -1 ? '_self' : '_blank'}
                        size={ButtonSize.small}
                        iconBefore={iconBefore}
                        key={iconBefore}
                        variant={isPlay6 ? ButtonVariant.primary : ButtonVariant.tertiary}
                        withHover={!!channel.href}
                        className={clsx(
                          isPlaySports && styles.channelsPlaySports,
                          isPlay6 && styles.channelsPlay6Mono,
                          isCanvas && styles.channelsVrtCanvas
                        )}
                        onClick={() => linkClickEvent({
                          targetPageUrl: channel.href,
                          targetPageName: targetUrl?.pathname ?? 'no page name provided',
                          targetPageId: channel.href,
                          linkTitle: channel.iconBefore?.replace('channels-', '') ?? 'no link title provided',
                        })}
                      />
                    })
                }
              </div>
            </div>
            : null
        }
      </Score>

      <Teamname
        size={TeamnameSize.XLarge}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={away as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={showLinks}
        logoPosition='top'
        isAway={true}
      />
    </div>
    {relatedMatch && <Tag
        className={clsx(
          styles.relatedMatch,
          styles.scoreboardContent
        )}
        href={relatedMatch?.componentProps.url ? relatedMatch?.componentProps.url : undefined}
    >
      <Teamname
        size={TeamnameSize.Small}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={relatedMatch.componentProps.home as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={false}
        logoPosition={'left'}
      />

      <Score layout={ScoreLayoutTypes.Default}
        score={relatedMatch.componentProps.score}
        sport={sport}
        status={relatedMatch.componentProps.status}
        label={relatedMatch.componentProps.label}
      />

      <Teamname
        size={TeamnameSize.Small}
        fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
        sport={sport}
        team={relatedMatch.componentProps.away as TeamnameProps['team']}
        showLogos={showLogos}
        showLinks={false}
        logoPosition='right'
        isAway={true}
      />
    </Tag>}
    {eventSets && <FieldTimeline status={status as StatusTypes} eventSets={eventSets}/>}
    {meta && <Caption className={styles.meta}>{meta}</Caption>}
    {goals && <Table className={styles.goals} {...goals} />}
  </>
}

export default DetailedScoreBoard

export type {
  ScoreboardScoreProps,
  ScoreboardLayoutTypes
}
