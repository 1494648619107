import type { VotingProps } from '../../../voting'
import type { MultiVote } from '@sporza/services'

import { useInteractionService } from '@sporza/hooks'
import { setStorage } from '@sporza/utils/storage'
import clsx from 'clsx'
import { FunctionComponent, useEffect, useState } from 'react'

import Paragraph from '../../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../../atoms/title'
import Button from '../../../../../molecules/button'
import { Notification, NotificationType } from '../../../../../molecules/notification'
import { VotingLayout, VotingPhase } from '../../../config'
import { OptionOptionProps, Selection, Sponsor } from '../components'
import { config } from '../select'
import commonStyles from '../select.module.scss'

interface SelectVotingCategoryProps {
  name: string
  description: string
  shortDescription: string
  votingId: string
  options: OptionOptionProps[]
}

interface SelectVotingProps extends VotingProps {
  votings?: SelectVotingCategoryProps[]
  aggregatorUrl?: string
  votingId?: string
}

const SelectVoting: FunctionComponent<SelectVotingProps> = (
  {
    slug,
    votingId,
    votings = [],
    handlePhaseChange,
    className,
    votingBaseUrl,
    aggregatorUrl,
    storageKey,
    layout = VotingLayout.Eddies,
    style
  }
) => {
  const { createVote: createInteractionVote } = useInteractionService(votingBaseUrl, slug)

  const [votingsSt, setVotingsSt] = useState(votings)
  const [category, setCategory] = useState<SelectVotingCategoryProps>(votingsSt[0])
  const [step, setStep] = useState(1)
  const [disabled, setDisabled] = useState(true)
  const [notification, setNotification] = useState<string>()

  const createVote = async () => {
    setNotification(undefined)

    if (!slug) {
      console.error('slug is missing')

      return
    }

    const selection = votingsSt
      ?.map((category: SelectVotingCategoryProps) => {
        const selected = category.options?.find((option) => option.selected)

        if (!selected) {
          return []
        }

        return  {
          kind: 'IdOnly',
          votingId: category.votingId,
          selection: [selected.interactionOptionId]
        }
      })

    const vote: MultiVote = {
      slug,
      entries: {
        kind: 'Multi',
        multiVotingId: votingId,
        entries: selection
      }
    }

    if (createInteractionVote) {
      const responseStatus = await createInteractionVote(vote)

      if (responseStatus > 299) {
        setNotification('er is iets misgegaan, probeer het later opnieuw')
      } else {
        setNotification('bedankt voor je stem!')
        handlePhaseChange && handlePhaseChange(VotingPhase.results, null, vote.entries)
      }
    }
  }

  const toggleOption = (option: any) => {
    const updatedOptions = category.options
      .map((o) => {
        const unSelected = category.options.find((o) => o === option && !o.selected) ? false : undefined
        if (o === option) {
          return {
            ...o,
            selected: o.selected ? undefined : true
          }
        } else {
          return {
            ...o,
            selected: unSelected
          }
        }
      })

    setCategory({
      ...category,
      options: updatedOptions
    })
  }

  const getSelectedOption = category?.options?.find((option) => option.selected)

  useEffect(() => {
    setNotification(undefined)
    setCategory(votingsSt[step-1])
  }, [step])

  useEffect(() => {
    setDisabled(!getSelectedOption)
    if (getSelectedOption){
      votingsSt[step-1] = category
      setStorage(storageKey, votingsSt)
    }
  }, [category])

  useEffect(() => {
    setVotingsSt(votings)
    setCategory(votings[0])
  }, [votings])

  return category && <div
    className={clsx(
      className
    )}
    style={style}
  >
    <div className={commonStyles.header}>
      <Sponsor svg={config[layout].sponsor} className={commonStyles.sponsor} />
      <Paragraph><b>{step}</b> van <b>{votingsSt.length}</b></Paragraph>
      <Title size={TitleSize.Large} desktopSize={TitleSize.XLarge}>{category?.name}<span className={commonStyles.accentPoint}>.</span></Title>
      <Paragraph>
        {category?.description}
      </Paragraph>
    </div>

    <Selection
      aggregatorUrl={aggregatorUrl}
      title={category?.name}
      options={category?.options}
      toggleOption={toggleOption} />

    <div className={commonStyles.buttonWrapper}>
      {step > 1 && <Button
        className={commonStyles.backButton}
        onClick={() => {
          setStep(step - 1)
        }}
        iconBefore="chevron-left"
      >vorige</Button>}

      <Button
        className={commonStyles.ctaButton}
        disabled={disabled}
        ariaLabel={disabled ? 'Kies eerst een optie hierboven' : undefined}
        onClick={() => {
          switch (true) {
            case getSelectedOption === undefined:
              setNotification('kies een optie')
              break
            case step === votingsSt.length:
              createVote()
              break
            default: {
              setStep(step + 1)
              break
            }
          }
        }}
        iconAfter="chevron-right"
      >volgende</Button>
    </div>

    {
      notification
      && <Notification
        type={NotificationType.Error}
        text={notification}
        darkMode={true}
      />
    }

  </div>
}

export {
  SelectVoting
}

export type {
  SelectVotingProps
}

