import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren, ReactElement, RefObject } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'
import type { FilterProps } from '../../molecules/filter'
import type { DataCardComponentProps, DataCardProps } from '../data-card'
import type { LivestreamCardComponentProps, LivestreamCardProps } from '../livestream-card'
import type { MediaCardComponentProps } from '../media-card'
import type { ScoreboardComponentProps } from '../scoreboard'
import type { StoryCardComponentProps, StoryCardProps } from '../story-card'

import { Theme } from '@sporza/config'
import { useApi } from '@sporza/hooks'
import { ComponentType } from '@sporza/services/component-mapper'
import clsx from 'clsx'
import { Fragment, useState } from 'react'

import Title, { TitleSize } from '../../atoms/title'
import { Avatar, AvatarProps } from '../../molecules/avatar'
import Button, { ButtonVariant } from '../../molecules/button'
import Image, { ImageLayout } from '../../molecules/image'
import { Banner } from '../banner'
import DataCard from '../data-card'
import { LivestreamCard } from '../livestream-card'
import { MediaCard, MediaModal } from '../media-card'
import { PodcastEpisodeCard, PodcastEpisodeCardComponentProps, PodcastEpisodeCardProps } from '../podcast-episode-card'
import { PodcastProgramCard, PodcastProgramCardComponentProps, PodcastProgramCardProps } from '../podcast-program-card'
import Scoreboard, { ScoreboardLayoutTypes } from '../scoreboard'
import StoryCard from '../story-card'
import Table, { TableProps } from '../table'

import styles from './layouts/common.module.scss'
import { SectionBackground, SectionLayout, SectionSpacing, SectionType } from './config'
import { useEbaData, useMediaModal, useSortedItems } from './hooks'
import {
  getHybridOptions,
  getImageLayout,
  getItemDesktopLayout,
  getItemLayout,
  getSectionElementTag,
  getTitleSize
} from './utils'

type SortConfigItem = {
  label: string,
  iconAfter: Icons
  sortKey: keyof SectionProps['items']
  order: 'ascending' | 'descending'
}

type SectionItem =
  StoryCardComponentProps
  | LivestreamCardComponentProps
  | DataCardComponentProps
  | MediaCardComponentProps
  | PodcastEpisodeCardComponentProps
  | PodcastProgramCardComponentProps


interface SectionComponentProps {
  componentProps: SectionProps
}

interface SectionProps extends PropsWithChildren {
  type?: SectionType
  theme?: Theme
  title?: string
  text?: string
  link?: string
  linkText?: string
  linkAction?: () => void
  items?: SectionItem[]
  itemsAmount?: number
  matchItems?: ScoreboardComponentProps[]
  imageLayout?: string | ImageLayout
  layout?: string | SectionLayout
  background?: string | SectionBackground
  darkMode?: boolean
  setActive?: (i: number) => void
  spacing?: SectionSpacing
  isNumbered?: boolean
  analyticsId?: string
  calculationId?: string
  componentType?: string
  className?: ClassValue
  gridCssModuleClassNames?: string[]
  showMoreTile?: boolean
  quickLinkTitle?: string
  quickLinks?: any
  logoUrl?: string
  isExpandable?: boolean
  limit?: number
  disableTracking?: boolean
  innerRef?: RefObject<HTMLElement>
  ebaData?: any,
  hybridOptions?: any
  sortConfig?: SortConfigItem[]
  headerActions?: ReactElement
  filter?: FilterProps
  handleFilter?: any
  sportApiUrl?: string
  interval?: number
  ariaLabelledBy?: string
}

const mapToSectionItem = (item: SectionItem, extraProps: any, ebaData: any) => {
  switch (item.componentType) {
    case ComponentType.DataCard:
      return <DataCard
        key={extraProps.key}
        {...extraProps}
        {...item.componentProps as DataCardProps}
      />
    case ComponentType.LivestreamCard:
      return <LivestreamCard
        key={extraProps.key}
        ebaData={ebaData}
        {...item.componentProps as LivestreamCardProps}
      />
    case ComponentType.PodcastProgramCard:
      return <PodcastProgramCard
        key={extraProps.key}
        ebaData={ebaData}
        {...item.componentProps as PodcastProgramCardProps}
      />
    case ComponentType.PodcastEpisodeCard:
      return <PodcastEpisodeCard
        key={extraProps.key}
        ebaData={ebaData}
        {...item.componentProps as PodcastEpisodeCardProps}
      />
    case ComponentType.MediaCard:
      return <MediaCard
        key={extraProps.key}
        ebaData={ebaData}
        {...extraProps}
        {...item.componentProps as MediaCardComponentProps}
      />
    case ComponentType.AvatarCard:
      return <Avatar
        key={extraProps.key}
        {...item.componentProps as AvatarProps}
        />
    case ComponentType.Table:
      return <Table
        key={extraProps.key}
        {...item.componentProps as TableProps}
      />
    case ComponentType.StoryCard:
    default:
      return <StoryCard
        ebaData={ebaData}
        {...extraProps}
        {...item.componentProps as StoryCardComponentProps}
        key={extraProps.key}
      />
  }
}

const Section: FunctionComponent<SectionProps> = (props) => {
  const [state, setState] = useState<any>(props)

  const { data } = useApi(state, {
    keyPrefix: 'section',
    fetchNow: state.fetchNow,
  })

  const {
    type = SectionType.Default,
    theme = Theme.Default,
    title,
    text,
    link,
    linkText,
    linkAction,
    items = [],
    matchItems,
    imageLayout,
    layout = SectionLayout.Grid2Column,
    background = SectionBackground.Default,
    darkMode,
    setActive,
    spacing = SectionSpacing.Default,
    isNumbered = false,
    analyticsId,
    calculationId,
    componentType,
    className,
    showMoreTile = false,
    quickLinkTitle,
    quickLinks,
    logoUrl,
    limit = 4,
    disableTracking,
    ebaData,
    sortConfig,
    isExpandable,
    ariaLabelledBy
  } = data || state

  const {
    sortedItems,
    sortCfg,
    setSortCfg
  } = useSortedItems(data, sortConfig)

  const {
    activeCard,
    setActiveCard,
    setPrevActiveCard,
    setNextActiveCard,
    setActiveCardWithId
  } = useMediaModal(sortedItems)
  const isDarkMode = darkMode || background === SectionBackground.Dark
  const ElementTag = getSectionElementTag(layout)

  const handleFilter = (filter: any) => {
    setState({
      ...state,
      sportApiUrl: filter.value,
      fetchNow: true
    })
  }

  const { enrichEbaDataWithItemData } = useEbaData(ebaData)

  let sort
  if (sortConfig?.length && sortCfg) {
    sort = <Button
      variant={ButtonVariant.tertiary}
      iconAfter={sortCfg.iconAfter as Icons}
      onClick={() => {
        const currentIndex = sortConfig.findIndex((obj: any) => obj === sortCfg)
        const nextIndex = (currentIndex + 1) % sortConfig.length

        setSortCfg(sortConfig[nextIndex])
      }}
      className={styles.sortButton}
    >{sortCfg.label}</Button>
  }

  return <>
    {
      sortedItems?.length
        ? <ElementTag
          title={title}
          itemsAmount={items?.length}
          quickLinkTitle={quickLinkTitle}
          text={text}
          link={link}
          linkText={linkText}
          linkAction={linkAction}
          layout={layout}
          spacing={spacing}
          background={background}
          darkMode={darkMode}
          isNumbered={isNumbered}
          analyticsId={analyticsId}
          componentType={componentType}
          className={clsx(
            className,
            type === SectionType.VerticalVideoPlaylist && styles.verticalVideoPlaylist,
            (theme === Theme.OlympischeSpelen2024 || theme === Theme.ParalympischeSpelen2024) && styles.themeOs2024,
            (theme === Theme.OlympischeSpelen2024 || theme === Theme.ParalympischeSpelen2024) && type === SectionType.MostTrending && styles.themeOs2024Blue,
            (theme === Theme.OlympischeSpelen2024 || theme === Theme.ParalympischeSpelen2024) && type === SectionType.Video && styles.themeOs2024Blue,
            (theme === Theme.OlympischeSpelen2024 || theme === Theme.ParalympischeSpelen2024)
              && type === SectionType.VerticalVideoPlaylist
              && layout === SectionLayout.Grid6ColumnSlider
              && styles.themeOs2024Tricolor,
            spacing === SectionSpacing.TopNone && styles.topNone
          )}
          limit={limit}
          isExpandable={isExpandable}
          hybridOptions={getHybridOptions(layout, items?.length)}
          headerActions={sort}
          filter={props.filter}
          handleFilter={handleFilter}
          ariaLabelledBy={ariaLabelledBy}
        >
          {
            sortedItems?.map((item, index: number) => {
              const {
                title: itemTitle,
                type: itemType,
                className,
                link
              } = item.componentProps as StoryCardProps

              return <Fragment key={`${index}-${itemTitle}`}>
                <div
                  onFocus={() => setActive && setActive(index)}
                  onMouseOver={() => setActive && setActive(index)}
                  className={clsx((
                    itemType === 'Podcast'
                      || item.componentType === ComponentType.AvatarCard
                      ||  layout === SectionLayout.Grid3ColumnScroll
                    ) && styles.scrollableItems,
                    styles.itemWrapper,
                    className
                  )}
                >
                  {
                    mapToSectionItem(
                      item,
                      {
                        key: link,
                        titleSize: getTitleSize(index, layout),
                        desktopTitleSize: getTitleSize(index, layout, true),
                        darkMode: isDarkMode,
                        layout: getItemLayout(index, item.componentProps as StoryCardProps, layout),
                        desktopLayout: getItemDesktopLayout(index, item.componentProps as StoryCardProps, layout),
                        imageLayout: getImageLayout(item.componentProps as StoryCardProps, layout, imageLayout),
                        imageChildren: isNumbered
                          ? <div className={styles.numbered}>
                            {index + 1}
                          </div>
                          : false,
                        onClick: item?.componentType === ComponentType.MediaCard
                          ? (selectedMediaIndex: number) => setActiveCardWithId(index, item.componentProps, selectedMediaIndex)
                          : undefined,
                        theme
                      },
                      !disableTracking
                        ? enrichEbaDataWithItemData({
                          calculationId,
                          analyticsId,
                          title,
                          type,
                          total: items?.length,
                          index,
                          item: item.componentProps
                        })
                        : undefined
                    )
                  }
                </div>
                {index === 0
                  && layout === SectionLayout.Grid2x4ColumnMatches
                  && matchItems
                  && matchItems.length > 0
                  && <div className={styles.matches}>
                    <Title className={styles.title} size={TitleSize.Large}>volgende wedstrijden</Title>
                    {
                      matchItems.map((item: ScoreboardComponentProps, index: number) =>
                        <Scoreboard
                          {...item.componentProps}
                          key={`${index}${JSON.stringify(item)}`}
                          layout={ScoreboardLayoutTypes.Compact}
                        />
                      )
                    }
                  </div>}
              </Fragment>
            })
          }
          {showMoreTile && <div className={clsx(
            styles.moreTile,
            darkMode && styles.darkMode
          )}>
            {
              logoUrl
              && <Image
                fallbackProfile='w160hx'
                profiles={[{ name: 'w160hx', view: '100w' }]}
                className={styles.logo}
                src={logoUrl}
              />
            }
            <Button darkMode={darkMode} variant={'primary'} iconAfter={'chevron-right'} href={link}>meer</Button>
          </div>}
          {quickLinks && <div className={styles.quickLinks}>
            {quickLinks.map((link: any, index: number) =>
              <Banner key={`${index}-${link.componentProps.title}`} {...link.componentProps} />
            )}
          </div>}
        </ElementTag>
        : null
    }

    {
      activeCard
        ? <MediaModal
          open={!!activeCard}
          media={activeCard?.playlist?.componentProps}
          title={activeCard?.title}
          iconBefore={activeCard?.iconBefore}
          selectedMediaIndex={activeCard?.selectedMediaIndex}
          theme={theme}
          onPrev={sortedItems && sortedItems.length > 1 ? () => setPrevActiveCard() : undefined}
          onNext={sortedItems && sortedItems.length > 1 ? () => setNextActiveCard() : undefined}
          onClose={() => setActiveCard(null)}
        />
        : null
    }
  </>
}

export default Section

export {
  Section
}

export type {
  SectionProps,
  SectionItem,
  SectionComponentProps
}
