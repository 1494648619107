import type { FunctionComponent } from 'react'

type SponsorProps = {
  className: string,
  svg: string
}

export const Sponsor: FunctionComponent<SponsorProps> = (
  {
    className,
    svg
  }) => <span className={className}>{svg}</span>
