import { getPermalinkId } from '@sporza/utils/urls'

import { addContextToEvent } from '../utils/eba'

import { getPageEventData } from './page-impression'
import { getSearchData } from './search'

interface ListImpressionArguments {
  componentName?: string
  componentPosition?: number
  componentCount?: number
  elementTitle?: string
  elementTargetUrl?: any
  listTitle?: string
  listAnalyticsId?: string
  listType?: string
  listOrientation?: string
  listElementCount?: number
  listElementPosition?: number
  calculationId?: string
  featureKey?: string
  featureValue?: string
  featureSource?: string
  featureRuleId?: string
  featureExtra?: string
}

const listImpressionEvent = (
  props: ListImpressionArguments
) => {
  const {
    componentName,
    componentPosition,
    componentCount,
    elementTitle,
    elementTargetUrl,
    listTitle,
    listAnalyticsId,
    listType,
    listOrientation,
    listElementCount,
    listElementPosition,
    calculationId,
    featureKey,
    featureValue,
    featureSource,
    featureRuleId,
    featureExtra,
  } = props

  if (typeof window === 'undefined') {
    console.warn('sporza eba - no list impression triggered, not running in browser')

    return
  }

  const trackUsingTemplate = window.VRT?.EBA?.EDDLHelper?.trackUsingTemplate
  const templates = window.VRT?.EBA?.defaultTemplates

  if (!trackUsingTemplate || !templates) {
    console.warn('sporza eba - could not find EBA utils to track list impression', props)

    return
  }

  try {
    const eventData: any = {
      '$liti': listTitle || 'no title defined',

      // optional
      '$lity': listType,
      '$lior': listOrientation,
      '$licp': componentPosition,
      '$licc': componentCount,
      '$licn': componentName,
      '$liid': listAnalyticsId,
      '$lici': calculationId,

      // list element
      '$leti': elementTitle,
      '$lepo': listElementPosition,
      '$leco': listElementCount,

      // feature
      '$feke': featureKey,
      '$feva': featureValue,
      '$fesr': featureSource,
      '$feri': featureRuleId,
      '$feex': featureExtra,
    }

    if (elementTargetUrl) {
      const targetUrl = elementTargetUrl instanceof URL
        ? elementTargetUrl
        : new URL(elementTargetUrl)

      eventData['$tapu'] = targetUrl.toString()
      eventData['$tapn'] = targetUrl.pathname
      eventData['$tapi'] = getPermalinkId(targetUrl.pathname) || targetUrl.pathname
    }

    const pageEventData = getPageEventData()
    const searchData = getSearchData()

    let listImpression = { ...templates.list_impression_1_1_0 }
    listImpression = addContextToEvent(listImpression, 'context_target')
    listImpression = addContextToEvent(listImpression, 'context_page')
    listImpression = addContextToEvent(listImpression, 'context_search')

    trackUsingTemplate(
      listImpression,
      {
        ...eventData,
        ...pageEventData,
        ...searchData
      }
    )
  } catch (e) {
    console.error('failed to send list impression event', e)
  }
}

export {
  listImpressionEvent
}

export type {
  ListImpressionArguments
}
