import 'swiper/css'

import clsx from 'clsx'
import { FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Icon from '../../../../../atoms/icon'
import { Breakpoints } from '../../../../../design-tokens/breakpoints'

import { DisplayType, Option, OptionOptionProps } from './option'
import styles from './selection.module.scss'

interface SelectionProps extends PropsWithChildren {
  title?: string
  options?: OptionOptionProps[]
  toggleOption?: (option: any) => void
  key?: string
  aggregatorUrl?: string
}

const Selection: FunctionComponent<SelectionProps> = (
  {
    title,
    options,
    toggleOption,
    aggregatorUrl
  }
) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const [swiper, setSwiper] = useState<any>(null)
  const [animate, setAnimate] = useState<any>(false)

  useEffect(() => {
    swiper?.update()
    swiper?.slideTo(0, 0)
  }, [title])

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current
      swiper.params.navigation.nextEl = nextRef.current
      swiper.navigation.init()
      swiper.navigation.update()
    }
  }, [swiper])

  const isVideo = options && options[0].display?.type !== DisplayType.Image

  return <Swiper
    key={`${title}-${isVideo}`}
    modules={[Navigation, A11y]}
    navigation={{
      prevEl: prevRef.current,
      nextEl: nextRef.current
    }}
    onSwiper={(swiper) => setSwiper(swiper)}
    breakpoints={{
      [Breakpoints.small]: {
        slidesPerView: isVideo ? 1.1 : 1.5
      },
      [Breakpoints.medium]: {
        slidesPerView: isVideo ? 3 : 4
      }
    }}
    centerInsufficientSlides={true}
    spaceBetween={20}
    role={'select'}
    className={clsx(
      styles.selection,
      isVideo && styles.isVideo,
      // animate === 'left' && styles.animateLeft,
      // animate === 'right' && styles.animateRight
    )}>
    {
      options?.map((option, index) => (
        <SwiperSlide key={option.title + option.subtitle + index}>
          <Option
            aggregatorUrl={aggregatorUrl}
            option={option}
            toggleOption={toggleOption}
          />
        </SwiperSlide>
      ))
    }
    <button key={`${title}-${isVideo}-prev`} ref={prevRef} className={styles.prev} onMouseOver={() => setAnimate('left')} onMouseOut={() => setAnimate(false)}>
      <Icon name={'chevron-left'} />
    </button>
    <button key={`${title}-${isVideo}-next`} ref={nextRef} className={styles.next} onMouseOver={() => setAnimate('right')} onMouseOut={() => setAnimate(false)}>
      <Icon name={'chevron-right'} />
    </button>
  </Swiper>
}

export {
  Selection
}
