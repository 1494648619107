enum DrawType {
  PotsGroups = 'pots-groups',
  PotsMatches = 'pots-matches',
  Selection = 'selection',
  IedereenBondscoachSelection = 'iedereen-bondscoach-selection'
}

const DrawTypes = Object.values<`${DrawType}`>(DrawType)

const selectionDrawTypes = [
  DrawType.Selection,
  DrawType.IedereenBondscoachSelection
]

enum DrawItemType {
  Team = 'team',
  Player = 'player'
}

function isSelectionDrawType(drawType: string | undefined) {
  return drawType && selectionDrawTypes.includes(drawType as DrawType)
}

function isIedereenBondscoach(drawType: string | undefined) {
  return DrawType.IedereenBondscoachSelection === drawType
}

function hasExternalId(drawType: string | undefined) {
  return isIedereenBondscoach(drawType)
}

export {
  DrawType,
  DrawTypes,
  DrawItemType,
  isSelectionDrawType,
  isIedereenBondscoach,
  hasExternalId
}
