import type { FunctionComponent } from 'react'

import { Theme } from '@sporza/config/themes'
import { mapToComponent } from '@sporza/services/component-mapper'
import clsx from 'clsx'

import { Advertisement, AdvertisementGroup } from '../../../molecules/advertisement'
import Cell from '../../cell'
import Container from '../../container'
import Grid from '../../grid'
import { NavigationSimple } from '../../navigation-simple'

import styles from './competition.module.scss'

interface CompetitionPageProps {
  theme: Theme,
  title: string,
  header: any[],
  main: any[],
  mainNav: any[],
  subNav: any[],
  apiBaseUrl?: string,
  designSystemBaseUrl?: string,
  bffBaseUrl?: string,
  classNames?: string
  hideAds?: boolean
}

const CompetitionPage: FunctionComponent<CompetitionPageProps> = (
  {
    theme,
    title,
    header: headerItems = [],
    main: mainItems = [],
    subNav = [],
    apiBaseUrl,
    designSystemBaseUrl,
    bffBaseUrl,
    classNames,
    hideAds
  }
) => {
  const headerChildren = !!headerItems?.length && <Grid>
    <Cell size={4}>
      {headerItems.map((item: any) => mapToComponent(item, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl }))}
    </Cell>
  </Grid>

  return <Container
    collapsed
    className={clsx(
      classNames,
      styles.competition,
      theme === Theme.OlympischeSpelen2024 && styles.OS2024,
      theme === Theme.ParalympischeSpelen2024 && styles.OS2024
    )}
  >
    {
      headerChildren
        ? <Container
          tag="header"
          id={'content'}
          collapsed
          className={clsx(
            classNames
          )}
        >
          {headerChildren}
        </Container>
        : false
    }
    <NavigationSimple tag={'nav'} ariaLabel={'subnavigatie'} items={subNav} />
    <Grid>
      <Cell size={4}>
        <main id={'content'}>
          {
            mainItems.map((item: any) =>
              mapToComponent(item, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl })
            )
          }
        </main>
      </Cell>
    </Grid>

    {!hideAds && <AdvertisementGroup>
      <Advertisement adType="cba"/>
      <Advertisement adType="cba"/>
      <Advertisement adType="cba"/>
      <Advertisement adType="cba"/>
    </AdvertisementGroup>}
  </Container>
}

export {
  CompetitionPage
}
