import type { FunctionComponent } from 'react'
import type {
  ResultMatchesProps,
  StartGroupsProps
} from '../../index'

import clsx from 'clsx'

import { StatusTypes } from '../../../../molecules/score'
import commonStyles from '../../draw.module.scss'
import {
  ResultMatches,
  StartGroups
} from '../../index'

import styles from './pots-matches.module.scss'

interface PotsMatchesProps {
  name?: string
  status?: string
  availableSlots: number | null
  startingGroups: StartGroupsProps | null
  resultGroups: ResultMatchesProps
  useOutset?: boolean
}

const PotsMatches: FunctionComponent<PotsMatchesProps> = (
  {
    name,
    status,
    startingGroups,
    resultGroups,
    availableSlots,
    useOutset
  }
) => {
  const isLive = status?.toLowerCase() === StatusTypes.Live.toLowerCase()
  const isEnd = status?.toLowerCase() === StatusTypes.End.toLowerCase() || status?.toLowerCase() === 'ended'

  return <div
    className={clsx(
      commonStyles.draw,
      useOutset && commonStyles.drawOutset,
      styles.potsMatches
    )}
    aria-label={name}
  >
    {
      startingGroups && !startingGroups.hidden && !isEnd
        ? <StartGroups
          {...startingGroups}
          isLive={isLive}
        />
        : false
    }
    {
      resultGroups && !resultGroups.hidden
        ? <ResultMatches
          {...resultGroups}
          isLive={isLive}
          availableSlots={availableSlots}
        />
        : false
    }
  </div>
}

export {
  PotsMatches
}

export type {
  PotsMatchesProps
}

