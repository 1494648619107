import type { FunctionComponent } from 'react'
import type { VotingProps } from '../../../voting'

import { useAuth, useScreenshot, useWebShareApi } from '@sporza/hooks'
import clsx from 'clsx'
import { useRef } from 'react'

import Paragraph from '../../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../../atoms/title'
import Button from '../../../../../molecules/button'
import { VotingLayout, VotingPhase } from '../../../config'
import { ShareScreenshot } from '../components'
import { Sponsor } from '../components'
import { config } from '../select'
import commonStyles from '../select.module.scss'

type SelectResultsProps = VotingProps

const SelectResults: FunctionComponent<SelectResultsProps> = (
  {
    votings,
    vote,
    broadcastDate,
    handlePhaseChange,
    className,
    layout = VotingLayout.Eddies,
    style
  }
) => {
  const ref = useRef(null)
  const {
    getImageBlob,
    imageExtension
  } = useScreenshot(ref)
  const { isWebShareApiSupported, share } = useWebShareApi()
  const { name } = useAuth()

  const subject = config[layout].subject

  const shareResult = async () => {
    const imageBlob = await getImageBlob()
    const shareData: ShareData = {
      title: `${name ? `${name}'s` : 'mijn'} votes`,
      text: `Dit zijn mijn votes voor de ${subject}`,
      url: window.location.href,
      files: [
        new File([imageBlob], `${name ? `${name}` : 'mijn'}-${subject}-votes.${imageExtension}`, {
          type: imageBlob.type,
        }),
      ],
    }

    await share(shareData)
  }

  const followMedia = () => {
    switch (true) {
      case config[layout].followMedia !== undefined:
        return <>Volg de uitzending van de {subject} op <b>{broadcastDate}</b> op {config[layout].followMedia}.</>
      default:
        return <>Kom op <b>{broadcastDate}</b> te weten wie de {subject} in de wacht sleept.</>
    }
  }

  return <div
    className={className}
    style={style}
  >
    <div className={clsx(
        commonStyles.header,
        commonStyles.headerCenter
    )}>
      <Sponsor svg={config[layout].sponsor} className={commonStyles.sponsor} />
      <Title size={TitleSize.Large} desktopSize={TitleSize.XLarge}>bedankt<span className={commonStyles.accentPoint}>.</span></Title>
      <Paragraph>We hebben je selectie goed ontvangen. {followMedia()}</Paragraph>
      <br/>
      {vote && <div className={commonStyles.buttonWrapper}>
        {isWebShareApiSupported
          && <Button
          className={commonStyles.ctaButton}
          onClick={() => shareResult()}
          iconAfter="chevron-right"
        >deel je stem</Button>}
        <a
          className={commonStyles.linkButton}
          onClick={() => {
            handlePhaseChange && handlePhaseChange(VotingPhase.voting, null, vote)
          }}
        >stem aanpassen</a>
      </div>}
      {vote && <ShareScreenshot layout={layout} broadcastDate={broadcastDate} votings={votings} vote={vote} reffy={ref} />}
    </div>
  </div>
}

export {
  SelectResults
}

export type {
  SelectResultsProps
}

