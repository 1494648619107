import Car from './icons/car.svg'
import Crash from './icons/crash.svg'
import DriveThrough from './icons/drive-through.svg'
import EveryoneFlagged from './icons/everyone-flagged.svg'
import Laps from './icons/laps.svg'
import Mechanical from './icons/mechanical.svg'
import Overtake from './icons/overtake.svg'
import PitIn from './icons/pit-in.svg'
import PitOut from './icons/pit-out.svg'
import PitStart from './icons/pit-start.svg'
import SafetyCar from './icons/safety-car.svg'
import SafetyCarExit from './icons/safety-car-off.svg'
import ShortCut from './icons/shortcut.svg'
import Spin from './icons/spin-off.svg'
import SpinOff from './icons/spin-off.svg'
import StopAndGo from './icons/stop-and-go.svg'
import TimePenalty from './icons/time-penalty.svg'
import TireChange from './icons/tire-change.svg'
import TrackConditionChange from './icons/track-condition-change.svg'
import VirtualLeader from './icons/virtual-leader.svg'
import Wheel from './icons/wheel.svg'
import WinnerFlagged from './icons/winner-flagged.svg'

export type IconNameFormula1 = keyof typeof iconsFormula1;

export const iconsFormula1 = {
  'formula1-car': Car,
  'formula1-crash': Crash,
  'formula1-drive-through': DriveThrough,
  'formula1-everyone-flagged': EveryoneFlagged,
  'formula1-laps': Laps,
  'formula1-mechanical': Mechanical,
  'formula1-mechanical-breakdown': Mechanical,
  'formula1-overtake': Overtake,
  'formula1-pit-in': PitIn,
  'formula1-pit-out': PitOut,
  'formula1-pit-start': PitStart,
  'formula1-safety-car': SafetyCar,
  'formula1-safety-car-exit': SafetyCarExit,
  'formula1-short-cut': ShortCut,
  'formula1-spin': Spin,
  'formula1-spin-off': SpinOff,
  'formula1-stop-and-go': StopAndGo,
  'formula1-time-penalty': TimePenalty,
  'formula1-tire-change': TireChange,
  'formula1-track-condition-change': TrackConditionChange,
  'formula1-virtual-leader': VirtualLeader,
  'formula1-wheel': Wheel,
  'formula1-winner-flagged': WinnerFlagged,
}
