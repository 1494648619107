import { getPermalinkId } from '@sporza/utils/urls'

import { addContextToEvent } from '../utils'

import { getPageEventData } from './page-impression'
import { getSearchData } from './search'

interface ListClickArguments {
  listAnalyticsId?: string
  listTitle?: string
  listType?: string
  listElementCount?: string
  listElementPosition?: string
  elementTargetUrl?: URL | string
  elementTitle?: string
  calculationId?: string
}

const listClickEvent = (
  {
    listAnalyticsId,
    listTitle,
    listType,
    listElementCount,
    listElementPosition,
    elementTargetUrl,
    elementTitle,
    calculationId
  }: ListClickArguments
) => {
  if (typeof window === 'undefined') {
    console.warn('sporza eba - no list click triggered, not running in browser')

    return
  }

  const trackUsingTemplate = window.VRT?.EBA?.EDDLHelper?.trackUsingTemplate
  const templates = window.VRT?.EBA?.defaultTemplates

  if (!trackUsingTemplate || !templates) {
    console.warn('sporza eba - could not find EBA utils to track list click')

    return
  }

  try {
    const eventData: Record<string, any> = {}

    let listClick = { ...templates.list_click_1_1_0 }
    listClick = addContextToEvent(listClick, 'context_page')
    listClick = addContextToEvent(listClick, 'context_search')

    if (listTitle) {
      eventData['$liti'] = listTitle
      eventData['$liid'] = listAnalyticsId
      eventData['$lity'] = listType
      eventData['$lici'] = calculationId
    }

    if (elementTargetUrl) {
      const targetUrl = elementTargetUrl instanceof URL
        ? elementTargetUrl
        : new URL(elementTargetUrl)

      eventData['$tapu'] = targetUrl.toString()
      eventData['$tapn'] = targetUrl.pathname
      eventData['$tapi'] = getPermalinkId(targetUrl.pathname) || targetUrl.pathname
    }

    if (elementTitle) {
      eventData['$leti'] = elementTitle
      eventData['$lepo'] = listElementPosition
      eventData['$leco'] = listElementCount
    }

    const pageEventData = getPageEventData()
    const searchData = getSearchData()

    trackUsingTemplate(
      listClick,
      {
        ...eventData,
        ...pageEventData,
        ...searchData
      }
    )
  } catch (e) {
    console.error('failed to send list click event', e)
  }
}

export {
  listClickEvent
}

export type {
  ListClickArguments
}
