import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'
import type { ButtonProps } from '../../../molecules/button'
import type { PillProps } from '../../../molecules/pill'

import { mapToComponent } from '@sporza/services/component-mapper'
import clsx from 'clsx'
import { Gallery } from 'react-photoswipe-gallery'

import Caption from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Meta, { MetaWeight } from '../../../atoms/meta'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import { Advertisement, AdvertisementGroup } from '../../../molecules/advertisement'
import Button from '../../../molecules/button'
import { PageHeader } from '../../../molecules/page-header'
import Pill from '../../../molecules/pill'
import Container from '../../../organisms/container'
import Navigation from '../../../organisms/navigation'
import { onBeforeOpen, onOpen, options } from '../../mastermedia'

import styles from './podcast-article.module.scss'

interface LayoutProps {
  pageType: string,
  label: ButtonProps,
  title: string,
  isExternal?: boolean,
  className?: ClassValue,
  main: any[],
  mainBody: any[],
  header: any[],
  apiBaseUrl?: string,
  publicationDate: string,
  publicationTime: string,
  designSystemBaseUrl?: string,
  bffBaseUrl?: string,
  shareLinks?: ButtonProps[],
  permalink?: string,
  aside?: any[],
  tags?: PillProps[]

}

const PodcastArticlePage: FunctionComponent<LayoutProps> = (
  {
    pageType,
    title,
    main,
    mainBody,
    header,
    apiBaseUrl,
    designSystemBaseUrl,
    publicationTime,
    publicationDate,
    shareLinks = [],
    permalink,
    aside,
    tags

  }
) => {
  return <>
    <PageHeader className={styles.topContainer}>
      <section className={styles.top}>
        <aside className={styles.topAside}>
          <div className={styles.topPillTitleWrapper}>
            <Pill iconBefore={'audio'} label={'PODCAST'} className={styles.topPill}/>
            {title && <Title tag={TitleElement.H1} size={TitleSize.Large}>{title}</Title>}
          </div>
          <div className={styles.publicationData}>
            <Meta weight={MetaWeight.Medium}>
              <Icon name="calendar" ariaHidden/>&nbsp;
              {publicationDate}
            </Meta>
            <Meta weight={MetaWeight.Medium}>
              <Icon name="clock" ariaHidden/>&nbsp;
              {publicationTime}
            </Meta>
          </div>
        </aside>
        <div className={styles.topMain}>
          {
            header?.map((item: any, index: number) =>
              mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
            )
          }
        </div>
      </section>
    </PageHeader>

    <Container className={styles.mainContainer}>
      <Gallery withCaption onBeforeOpen={onBeforeOpen} onOpen={onOpen} options={options}>
        <section className={styles.shareLinks}>
          <Caption weight={'Medium'}>deel deze aflevering</Caption>
          <Navigation
            variant="pill-horizontal"
            items={[
              ...shareLinks,
              {
                iconBefore: 'link',
                showOnMobile: !!permalink,
                showOnDesktop: !!permalink,
                onClick: () => {
                  permalink && navigator?.clipboard?.writeText(permalink)
                }
              },
            ]}
            className={styles.share}
          />
        </section>

        <section className={styles.middle}>
          <aside className={styles.middleAside}>
            {
              aside?.map((item: any, index: number) =>
                mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
              )
            }
          </aside>
          <div className={styles.middleMain}>
            {
              main?.map((item: any, index: number) =>
                mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
              )
            }
            <div className={clsx(
              styles.tags,
              'sw-article-tags'
            )}>
              {
                tags?.map(tag =>
                  <Pill key={tag.label} {...tag} iconAfter="chevron-right" className="sw-article-tag"/>
                )
              }
            </div>
            <div className={styles.contact}>
              <Button
                href="https://sporza.be/nl/taalfout-opgemerkt/"
                target="_blank"
                iconAfter="typo"
              >taal- of schrijffout opgemerkt?
              </Button>
            </div>
          </div>
        </section>

        <section className={styles.bottom}>
          {
            mainBody?.map((item: any, index: number) =>
              mapToComponent(item, { apiBaseUrl, designSystemBaseUrl })
            )
          }
        </section>

        <AdvertisementGroup>
          <Advertisement adType="cba"/>
          <Advertisement adType="cba"/>
          <Advertisement adType="cba"/>
          <Advertisement adType="cba"/>
        </AdvertisementGroup>
      </ Gallery>
    </Container>
  </>
}
export {
  PodcastArticlePage
}
