import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react'

import { ArticleMatchPage, ArticlePage } from './articles'
import { CompetitionPage } from './competition'
import { DefaultPage } from './default'
import { PodcastArticlePage, PodcastEpisodePage, PodcastProgramPage } from './podcasts'
import { VideoPage } from './videos'

interface PageProps extends PropsWithChildren {
  headerChildren?: ReactNode
  className?: ClassValue
  accessibilityTitle?: string
  title?: string
  pageType?: string
  hideAds?: boolean
}

enum PageType {
  Article = 'article',
  ArticleMatch = 'article-match',
  ArticlePodcast = 'article-podcast',
  ArticleVideo = 'article-video',
  Category = 'category',
  Competition = 'competition',
  Home = 'home',
  Player = 'player',
  Podcast = 'podcast',
  PodcastEpisode = 'podcast-episode',
  PodcastProgram = 'podcast-program',
  Promo = 'page-promo',
  Team = 'team',
  Theme = 'theme',
  Search = 'zoek'
}

const Page: FunctionComponent<PageProps> = (props) => {
  const {
    pageType = ''
  } = props

  const Layouts: {[Key in PageType as string]: FunctionComponent<any>} = {
    [PageType.Article]:         ArticlePage,
    [PageType.ArticleMatch]:    ArticleMatchPage,
    [PageType.ArticleVideo]:    VideoPage,
    [PageType.ArticlePodcast]:  PodcastArticlePage,
    [PageType.Competition]:     CompetitionPage,
    [PageType.PodcastEpisode]:  PodcastEpisodePage,
    [PageType.PodcastProgram]:  PodcastProgramPage
  }

  const PageLayout = Layouts[pageType] || DefaultPage

  return <PageLayout {...props} />
}

export {
  Page,
  PageType
}

export type { PageProps }

