import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'
import type { ButtonProps } from '../../../molecules/button'
import type { ImageComponentProps } from '../../../molecules/image'
import type { SectionProps } from '../../section'

import clsx from 'clsx'

import Caption from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Meta from '../../../atoms/meta'
import Paragraph from '../../../atoms/paragraph'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import { Advertisement, AdvertisementGroup } from '../../../molecules/advertisement'
import Button from '../../../molecules/button'
import Image, { ImageLayout } from '../../../molecules/image'
import { PageHeader } from '../../../molecules/page-header'
import Cell from '../../cell'
import Container from '../../container'
import Grid from '../../grid'
import Section from '../../section/section'

import styles from './podcasts.module.scss'

interface PodcastProgramPageProps {
  pageType: string,
  label: ButtonProps,
  title: string,
  image: ImageComponentProps,
  meta: string,
  description: string,
  lastEpisode: ButtonProps,
  info: string,
  episodes: {
    componentProps: SectionProps
  },
  hosts: ButtonProps[],
  mostRecentPrograms?: {
    componentProps: SectionProps
  },
  isExternal?: boolean,
  className?: ClassValue
}

const PodcastProgramPage: FunctionComponent<PodcastProgramPageProps> = (
  {
    pageType,
    label,
    title,
    image,
    meta,
    description,
    lastEpisode,
    info,
    episodes,
    hosts,
    mostRecentPrograms,
    isExternal,
    className
  }
) => {
  return <main id={'content'}>
    <PageHeader className={styles.headerContainer}>
      <Grid className={styles.header}>
        <Cell
          size={1}
          className={styles.headerImage}
        >
          {
            image
            && <Image
              {...image.componentProps}
              className={styles.podcastImage}
              layout={ImageLayout.Square}
            />
          }
        </Cell>
        <Cell
          size={3}
          className={styles.headerContent}
        >
          <div>
            {
              label
              && <Button
                {...label}
                className={styles.label}
                withIconBackground={true}
              >{label.title}</Button>
            }
          </div>
          {
            title
            && <Title
              tag={TitleElement.H1}
              size={TitleSize.Large}
            >{title}</Title>
          }
          {
            meta
            && <Meta>{meta}</Meta>
          }
          {
            description
            && <Paragraph className={styles.description}>{description}</Paragraph>
          }
          <div>
            {
              lastEpisode
              && <Button
                {...lastEpisode}
                className={styles.lastEpisode}
              ><Title size={TitleSize.XSmall}>{lastEpisode.title}</Title></Button>
            }
          </div>
          {
            info && <Caption className={styles.programInfo}><Icon name="info"/> {info}</Caption>
          }
        </Cell>
      </Grid>
    </PageHeader>
    <Container tag={'div'}>
      <Grid className={styles.main}>
        <Cell
          size={1}
          className={styles.hosts}
        >
          {
            hosts
              ? <>
                {
                  hosts.length
                    ? <Title size={TitleSize.Large}>{hosts.length > 1 ? 'podcasthosts' : 'podcasthost'}</Title>
                    : false
                }

                {
                  hosts.map((host: any) =>
                    <Button
                      {...host}
                      withIconBackground={true}
                    >{host.title}</Button>)
                }
              </>
              : false
          }
        </Cell>
        <Cell size={3}>
          {
            episodes
            && <Section
              {...episodes.componentProps}
              className={clsx(
                styles.episodes,
                isExternal && styles.external
              )}
            />
          }
        </Cell>
          {
            <Cell size={4}>
              {
                mostRecentPrograms
                && <Section
                  {...mostRecentPrograms.componentProps}
                  className={styles.mostRecentPrograms}
                />
              }
            </Cell>
          }
      </Grid>

      <AdvertisementGroup>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
      </AdvertisementGroup>
    </Container>
  </main>
}

export {
  PodcastProgramPage
}
