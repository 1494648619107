import type { FunctionComponent, PropsWithChildren } from 'react'

import clsx from 'clsx'

import Container from '../../organisms/container'

import styles from './page-header.module.scss'

interface PageHeaderProps extends PropsWithChildren {
  className?: string
}

const PageHeader: FunctionComponent<PageHeaderProps> = (
  {
    className,
    children
  }
) => {
  return <div
    className={clsx(
      styles.pageHeader,
      className
    )}
  >
    <Container tag='div' className={clsx(styles.pageHeaderContainer)}>
      {children}
    </Container>
  </div>
}

export {
  PageHeader
}

export type {
  PageHeaderProps
}
