import type { VotingProps } from '../../../voting'

import { useAuth } from '@sporza/hooks'
import clsx from 'clsx'
import { FunctionComponent } from 'react'

import Paragraph from '../../../../../atoms/paragraph'
import Title, { TitleSize } from '../../../../../atoms/title'
import Button from '../../../../../molecules/button'
import { VotingLayout, VotingPhase } from '../../../config'
import { LoginButton, Sponsor } from '../components'
import { config } from '../select'
import commonStyles from '../select.module.scss'

import styles from './landing.module.scss'

type SelectLandingProps = VotingProps & {
  isLive: boolean,
  broadcastDate: string,
}

const SelectLanding: FunctionComponent<SelectLandingProps> = (
  {
    isLive,
    broadcastDate,
    handlePhaseChange,
    className,
    layout = VotingLayout.Eddies,
    style
  }
) => {
  const { isLoggedIn, name } = useAuth()
  const subject = config[layout].subject
  const prize = config[layout].prize
  const title = 'breng je stem uit'
  const greeting = name && name.trim() !== '' ? `Dag ${name}, ` : ''
  const subtitle = isLive
    ? <>{greeting ? `${greeting}s` : 'S'}tem in elke categorie op je favoriet en bepaal mee wie er <b>op {broadcastDate}</b> met een {prize} naar huis gaat. <b>Let op:</b> je stem is pas geldig na het bevestigen van de laatste categorie.</>
    : <>{greeting ? `${greeting}d` : 'D'}e stemming voor de {subject} is nog niet gestart. Kom later terug om je favorieten te kiezen en bepaal mee wie er zich <b>op {broadcastDate}</b> met een {prize} naar huis gaat.</>

  return <div className={className} style={style}>
    <div className={clsx(
      commonStyles.header,
      commonStyles.headerCenter
    )}>
      <Sponsor svg={config[layout].sponsor} className={commonStyles.sponsor} />
      <Title size={TitleSize.Large} desktopSize={TitleSize.XLarge}>{title}<span className={commonStyles.accentPoint}>.</span></Title>
      <Paragraph className={styles.subtitle}>{subtitle}</Paragraph>
    </div>
    {
      isLive
        ?
        isLoggedIn
          ? <Button
            className={commonStyles.ctaButton}
            iconAfter="chevron-right"
            onClick={() => handlePhaseChange && handlePhaseChange(VotingPhase.voting)}
          >
            ok&eacute;, ik begin eraan
          </Button>
          : <LoginButton/>
        : <>we beginnen er zo dadelijk aan</>
    }
  </div>
}

export {
  SelectLanding
}

export type {
  SelectLandingProps
}

