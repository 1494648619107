import type { ClassValue } from 'clsx'
import type { FunctionComponent } from 'react'
import type { ImageComponentProps } from '../../../molecules/image'
import type { MediaplayerComponentProps } from '../../mediaplayer'
import type { SectionComponentProps } from '../../section'

import clsx from 'clsx'

import Icon from '../../../atoms/icon'
import Meta from '../../../atoms/meta'
import Paragraph from '../../../atoms/paragraph'
import Title, { TitleSize } from '../../../atoms/title'
import { Advertisement, AdvertisementGroup } from '../../../molecules/advertisement'
import Button from '../../../molecules/button'
import { ButtonProps } from '../../../molecules/button'
import Image, { ImageLayout } from '../../../molecules/image'
import { PageHeader } from '../../../molecules/page-header'
import Cell from '../../cell'
import Container from '../../container'
import Grid from '../../grid'
import Mediaplayer from '../../mediaplayer'
import Section from '../../section/section'

import styles from './podcasts.module.scss'

interface PodcastEpisodePageProps {
  pageType: string,
  label: ButtonProps,
  title: string,
  image: ImageComponentProps,
  meta: string,
  description: string,
  lastEpisode: ButtonProps,
  episodes: SectionComponentProps,
  episode: MediaplayerComponentProps,
  hosts: ButtonProps[],
  isExternal?: boolean,
  className?: ClassValue
}

const PodcastEpisodePage: FunctionComponent<PodcastEpisodePageProps> = (
  {
    pageType,
    label,
    title,
    image,
    meta,
    description,
    episodes,
    episode,
    hosts,
    isExternal,
    className
  }
) => {
  return <main id={'content'}>
    <PageHeader className={styles.headerContainer}>
      <Grid className={clsx(
        styles.header,
        styles.episodePage,
      )}>
        <Cell
          size={1}
          className={styles.headerImage}
        >
          {
            image
            && <Image
              {...image.componentProps}
              className={styles.podcastImage}
              layout={ImageLayout.Square}
            />
          }
        </Cell>
        <Cell
          size={3}
          className={styles.headerContent}
        >
          <div>
            {
              label
              && <Button
                {...label}
                className={styles.label}
                withIconBackground={true}
              >{label.title}</Button>
            }
          </div>
          {
            title
            && <Title
              tag="h1"
              size={TitleSize.Large}
            >{title}</Title>
          }
          {
            meta
            && <Meta>{meta}</Meta>
          }
          <div className={styles.mediaplayer}>
            {
              episode
              && <Mediaplayer {...episode.componentProps} />
            }
          </div>
        </Cell>
      </Grid>
    </PageHeader>
    <Container tag={'div'} className={styles.mainContainer}>
      <Grid className={styles.main}>
        <Cell
          size={1}
          className={styles.hosts}
        >
          {
            hosts
            && <>
              {
                hosts.length
                  ? <Title size={TitleSize.Large}>{hosts.length > 1 ? 'podcasthosts' : 'podcasthost'}</Title>
                  : false
              }

              {
                hosts.map((host: any) =>
                  <Button
                    {...host}
                    withIconBackground={true}
                  >{host.title}</Button>)
              }
            </>
          }
        </Cell>
        <Cell
          size={3}
          className={clsx(
            episode && styles.mainContentWithEpisode,
          )}
        >
          {
            description
            && <div className={styles.episodeInfo}>
              <Title size={TitleSize.Small}><Icon name={'info'}/> info aflevering</Title>
              <Paragraph>{description}</Paragraph>
            </div>
          }
          {
            episodes
            && <Section
              {...episodes.componentProps}
              className={clsx(
                styles.episodes,
                isExternal && styles.external
              )}
            />
          }
        </Cell>
      </Grid>

      <AdvertisementGroup>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
      </AdvertisementGroup>
    </Container>
  </main>
}

export {
  PodcastEpisodePage
}
