import clsx from 'clsx'
import { paramCase } from 'param-case'
import { FunctionComponent, useEffect, useState } from 'react'
import { useRef } from 'react'

import Button, { ButtonProps, ButtonVariant } from '../../molecules/button'
import Image from '../../molecules/image'

import styles from './navigation.module.scss'
import IntersectionObserverWrapper from './observer'

type NavigationVariant = 'top' | 'main' | 'tertiary' | 'pill-horizontal' | 'vertical'

type NavigationItem = ButtonProps & {
  items?: NavigationItem[]
  component?: any
  showOnDesktop?: boolean
  showOnMobile?: boolean
  hideLabelOnTablet?: boolean
  callback?: object,
  level?: number
  bypassRootOnly?: boolean
}

interface NavigationProps {
  variant?: NavigationVariant
  className?: string
  listClassName?: string
  items?: NavigationItem[]
  overflow?: boolean
  level?: number,
  action?: (targetId: string, item: any) => void,
  open?: any,
  rightItems?: NavigationItem[]
  location?: Location
  azLink?: string
  tag?: string
  ariaLabel?: string
  setHeightCallback?: any
  setExtraHeightCallback?: any
}

const navigationStyles: {
  [Key in NavigationVariant]: string
} = {
  'top': styles.navigationTop,
  'main': styles.navigationMain,
  'tertiary': styles.navigationTertiary,
  'pill-horizontal': styles.navigationPillHorizontal,
  'vertical': styles.navigationVertical
}

const buttonVariantMap: {
  [key in NavigationVariant]: keyof typeof ButtonVariant
} = {
  'top': 'primary',
  'main': 'tertiary',
  'tertiary': 'tertiary',
  'pill-horizontal': 'tertiary',
  'vertical': 'tertiary'
}

const getActiveChild = (navigationItem: NavigationItem) => {
  const active = navigationItem?.items && navigationItem.items.length > 0
    && navigationItem.items.find((item: NavigationItem) => item.active)

  const activeChild: any = active && active?.items && getActiveChild(active)

  return activeChild || active
}

const Navigation: FunctionComponent<NavigationProps> = ({
  action = () => void 0,
  open = [],
  variant = 'top',
  className,
  listClassName,
  items = [],
  rightItems = [],
  overflow = false,
  level = 1,
  azLink,
  tag = 'div',
  ariaLabel,
  setHeightCallback,
  setExtraHeightCallback
}) => {
  const Element = overflow ? IntersectionObserverWrapper : 'ul'
  const Tag = tag
  const navRef = useRef(null)
  const activeRef = useRef(null)

  const [extraHeight, setExtraHeight] = useState<number>(0) // State for storing the height of the navigation

  useEffect(() => {
    if (setExtraHeightCallback) setExtraHeightCallback(extraHeight)
  }, [extraHeight])

  useEffect(() => {
    if (!setHeightCallback || !navRef.current) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === navRef.current) {
          setHeightCallback(entry.contentRect.height)
        }
      }
    })

    resizeObserver.observe(navRef.current)

    return () => {
      resizeObserver.disconnect() // Cleanup observer
    }
  }, [setHeightCallback])

  const isExpandable = (item: NavigationItem) => item?.iconAfter

  const getActiveMegaMenuSubItems = (items?: NavigationItem[]) => {
    if (!items?.length) return false

    let r, level = 0

    const getActiveItem = (items: NavigationItem[]) => {
      level++

      items?.forEach((item: NavigationItem) => {
        if (item?.active && item.items) {
          r = item.items

          getActiveItem(item.items)
        }
      })
    }

    getActiveItem(items)

    // only return sub mega menu
    return level === 3 && r
  }

  return items.length > 0
    ? <Tag
        ref={navRef} className={clsx(
          navigationStyles[variant],
          className
        )}
      aria-label={ariaLabel}
      >
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <Element
        role='list'
        className={clsx(
          styles.navigationList,
          listClassName
        )}
      >
        {
          items.map((item, itemIndex) => {
            const { title, showOnDesktop = true, hideLabelOnTablet = false, active } = item

            // item.href = undefined

            let { component } = item

            const props = {
              ...item
            }

            const targetId = `${item.level || level}-${title}`
            const maxItems = 25

            if (variant === 'tertiary') {
              component = <div className={styles.navigationItemWrapper}>
                <a href={item.href} target={item.target} className={styles.navigationListTitle} aria-current={active && !getActiveChild(item) && 'page'}>{title}</a>
                <ul className={clsx(
                  styles.navigationItem,
                  item.items?.length && item.items?.length > maxItems && styles.navigationItemFlexRow
                )}>
                  {
                    item.items?.map((item, index) =>
                      <li
                        key={title ? paramCase(`${index}-${title}`) : `${itemIndex}-${index}`}
                        data-targetid={targetId}
                        className={clsx(
                          index >= maxItems && styles.hide
                        )}
                      >
                        <Button
                          href={item.href}
                          target={item.target}
                          variant={buttonVariantMap[variant] as ButtonVariant}
                          imageBefore={item.imageBefore}
                          imageBeforeNoPlaceholder={true}
                          iconBefore={item.iconBefore}
                          className={clsx(
                            styles.navigationItemWithIcon,
                            item.hideLabelOnTablet && styles.navigationItemHideLabelOnTablet,
                            item.active && styles.navigationItemActive
                          )}
                        >
                          {item.title}
                        </Button>
                      </li>
                    )
                  }
                </ul>
                {items?.length-1 === itemIndex && item.items?.length && item.items?.length > maxItems && <div className={styles.navigationFooter}>
                  {azLink && <a className={styles.navigationFooterLink} href={azLink}>A-Z alle sporten</a>}
                </div>}
              </div>
            }

            const toggleMegaMenu = (open: boolean) => {
              if (isExpandable(item) && open) {
                action(
                  targetId,
                  {
                    ...item,
                    level: item.level || level
                  }
                )
              }
            }

            let delayed: any

            const megaMenuSubItems = item.title === 'meer sport' && getActiveMegaMenuSubItems(item.items)

            const meerLabel = () => {
              const child = getActiveChild(item)

              if (child && item.iconAfter) {
                const icon = child.imageBefore ?
                  <Image src={child.imageBefore} className={styles.imageBefore} alt={child.title}/> : ''

                return <span className={styles.meerLabel}>: {icon}{child.title}</span>
              }
            }

            return <li
              className={clsx(
                itemIndex === 0 && component && level === 2 && items.length === 2 && styles.navigationBackground
              )}
              ref={active ? activeRef : undefined}
              key={title ? paramCase(`${itemIndex}-${title}`) : itemIndex}
              data-targetid={targetId}
              onMouseLeave={() => {
                delayed = setTimeout(() => {
                  toggleMegaMenu(open.includes(targetId))
                }, 200)
              }}
              onMouseEnter={() => {
                clearTimeout(delayed)
              }}
            >
              {
                component
                || <Button
                  onClick={() => {
                    action(
                      targetId,
                      {
                        ...item,
                        level: item.level || level
                      }
                    )

                    document.addEventListener('keydown', (event: KeyboardEvent) => {
                      if (event.key === 'Escape') {
                        action(
                          targetId,
                          {
                            ...item,
                            level: item.level || level
                          }
                        )
                      }
                    }, { once: true })
                  }}
                  onMouseEnter={() => {
                    toggleMegaMenu(!open.includes(targetId))
                  }}
                  aria-expanded={open.includes(targetId) ? true : isExpandable(item) ? false : undefined}
                  imageBeforeNoPlaceholder={true}
                  variant={buttonVariantMap[variant] as ButtonVariant || 'primary'}
                  className={clsx(
                    styles.navigationItem,
                    !showOnDesktop && styles.navigationItemMobileOnly,
                    hideLabelOnTablet && styles.navigationItemHideLabelOnTablet,
                    item.variant === 'tertiary' && styles.tertiaryListButton,
                    active ? styles.navigationItemActive : undefined
                  )}
                  aria-current={active && !getActiveChild(item) && 'page'}
                  {...props}
                >
                  {title}{meerLabel()}
                </Button>}

              {
                item.items
                && (!isExpandable(item) && variant === 'main' && active) || open[level] === targetId
                  ? <Navigation
                    overflow={overflow}
                    variant={(item.variant || variant) as NavigationVariant}
                    items={item.items}
                    level={level + 1}
                    open={open}
                    action={action}
                    className={className}
                    azLink={azLink}
                    setHeightCallback={item.variant !== 'tertiary' && setExtraHeight}
                  />
                  : null
              }
              {
                variant !== 'vertical' && megaMenuSubItems && <Navigation
                  variant={'main'}
                  items={megaMenuSubItems}
                  action={action}
                  className={clsx(
                    styles.megaMenuSubNavigation,
                    className
                  )}
                />
              }
            </li>
          })
        }

        {
          rightItems?.map((item, itemIndex) => {
            const { title, active, component, showOnDesktop = true, hideLabelOnTablet = true, items } = item
            const targetId = `${item.level || level}-${title}`

            return <li
              key={title ? paramCase(`${itemIndex}-${title}`) : itemIndex}
              data-targetid={`${item.level || level}-${title}`}
              className={styles.rightItem}
            >
              {
                component
                || <><Button
                  onClick={() =>
                    action(
                      targetId,
                      {
                        ...item,
                        level: item.level || level
                      }
                    )
                  }
                  variant={buttonVariantMap[variant] as ButtonVariant || 'primary'}
                  className={clsx(
                    styles.navigationItem,
                    !showOnDesktop && styles.navigationItemMobileOnly,
                    hideLabelOnTablet && styles.navigationItemHideLabelOnTablet,
                    active ? styles.navigationItemActive : undefined
                  )}
                  {...item}
                  aria-current={active && !getActiveChild(item) && 'page'}
                  href={items && variant === 'vertical' ? undefined : item.href}
                  target={item.target}
                >
                  {title}
                </Button>
                {
                  item.items
                  && (variant === 'main' && active) || open[level] === targetId
                    ? <Navigation
                      variant={(item.variant || variant) as NavigationVariant}
                      items={item.items}
                      level={level + 1}
                      open={open}
                      action={action}
                      className={className}
                      azLink={azLink}
                    />
                    : null
                }
                </>
              }
            </li>
          })
        }

      </Element>
    </Tag>
    : null
}

export default Navigation

export {
  getActiveChild
}

export type {
  NavigationItem,
  NavigationProps
}
