import type { FunctionComponent } from 'react'

import { useListClick } from '@sporza/tracking'
import clsx from 'clsx'
import { useRef } from 'react'

import Caption from '../../../atoms/caption'
import Button, { ButtonSize, ButtonVariant } from '../../../molecules/button'

import styles from './custom.cell.module.scss'

interface CellWithMatchReportProps {
  name: any,
  ebaData?: any
}

const CellWithMatchReport: FunctionComponent<CellWithMatchReportProps> = ({ name, ebaData }) => {
  const ref = useRef(null)

  const { href, rewatchHref, rewatchIcon, rewatchLabel } = name

  useListClick({
    ref,
    disableTracking: !!ebaData,
    ...ebaData,
    elementTargetUrl: href,
    elementTitle: 'wedstrijdverslag',
  })

  return <div className={clsx(
    styles.matchReport,
    styles.buttons
  )}>
    {href && <Caption>uitzending afgelopen, bekijk het <a ref={ref} href={href}>wedstrijdverslag</a>{rewatchHref && ' of '}</Caption>}
    {rewatchHref && <Button
      variant={ButtonVariant.secondary}
      size={ButtonSize.small}
      iconBefore={rewatchIcon ?? 'channels-vrt-max'}
      href={rewatchHref}
    >{rewatchLabel}</Button>}
  </div>
}

export default CellWithMatchReport
