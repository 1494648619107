import { waitForGlobal } from '@sporza/utils/objects'
import { useEffect, useState } from 'react'

enum AuthState {
  None = 'none',
  LoggedIn = 'loggedIn',
  Refresh = 'refresh',
  LoggedOut = 'loggedOut',
  Disabled = 'disabled',
}

const authStateMap = new Map<number, AuthState>([
  [0, AuthState.None],
  [1, AuthState.LoggedIn],
  [2, AuthState.Refresh],
  [-1, AuthState.LoggedOut],
  [-2, AuthState.Disabled],
])

const useAuth = () => {
  const [hasAuthHelpers, setHasAuthHelpers] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [marketingId, setMarketingId] = useState()
  const [name, setName] = useState('')

  const setAuthState = () => {
    const authStateKey = window.VRT.SSOController.getState()
    const userData: any = window.VRT.SSOController.getUserData()

    switch (authStateMap.get(authStateKey)) {
      case AuthState.LoggedIn:
      case AuthState.Refresh:
        setIsLoggedIn(true)
        break
      case AuthState.None:
      case AuthState.LoggedOut:
      case AuthState.Disabled:
      default:
        setIsLoggedIn(false)
        break
    }

    setMarketingId(userData?.marketing_id)
    setName(`${userData?.given_name || ''} ${userData?.last_name || ''}`.trim())
  }

  useEffect(() => {
    waitForGlobal(
      window,
      'VRT.SSOController',
      () => {
        if (
          'getState' in window.VRT.SSOController
          && 'getUserData' in window.VRT.SSOController
          && 'addEventListener' in window.VRT.SSOController
          && 'removeEventListener' in window.VRT.SSOController
        ) {
          setHasAuthHelpers(true)
          setAuthState()
        } else {
          console.warn('useAuth - VRT.SSOController has missing properties')
        }
      })
  }, [])

  useEffect(() => {
    if (hasAuthHelpers) {
      window.VRT.SSOController.addEventListener(
        'SSO-stateChanged',
        setAuthState,
      )
    }

    return () => {
      if (hasAuthHelpers) {
        window.VRT.SSOController.removeEventListener(
          'SSO-stateChanged',
          setAuthState,
        )
      }
    }
  }, [hasAuthHelpers])

  return {
    isLoggedIn,
    marketingId,
    name
  }
}

export {
  useAuth
}
