import { MultiVote, Vote } from '@sporza/services'
import { FunctionComponent, PropsWithChildren } from 'react'

import Icon from '../../../../../atoms/icon'
import Paragraph from '../../../../../atoms/paragraph'
import Image from '../../../../../molecules/image'
import { VotingLayout } from '../../../config'
import { config } from '../select'

import styles from './share-screenshot.module.scss'
import { Sponsor } from './sponsor'

interface ShareScreenshotProps extends PropsWithChildren {
  vote: MultiVote
  votings: any
  reffy?: any
  layout?: VotingLayout
  broadcastDate?: any
}

const ShareScreenshot: FunctionComponent<ShareScreenshotProps> = ({ votings, vote, reffy, broadcastDate, layout = VotingLayout.Eddies }) => {
  const votingsWithShortDescription = vote?.entries?.map((category) => {
    const matchingCategory = votings?.find((voting: any) => voting.votingId === category.votingId)
    const matchingVote = vote?.entries?.find((entry: any) => entry.votingId === matchingCategory?.votingId)
    const selection = matchingCategory?.options.find((option: any) => option.interactionOptionId === matchingVote.selection[0])

    return {
      ...category,
      shortDescription: matchingCategory?.shortDescription || matchingCategory?.name,
      selection: selection?.shortTitle
    }
  })

  return <div className={styles.screenshot} aria-hidden={true} ref={reffy} style={{ '--voting-background-image' : `url(${config[layout].background})` }}>
    <Sponsor svg={config[layout].sponsor} className={styles.sponsor}/>
    <div className={styles.bg}></div>
    <Icon name={'sporza-small-dark'} className={styles.screenshotSporzaIcon}/>
    <div className={styles.screenshotHeader}>
      <Image src={config[layout].shareHeader} priority={true} noCrop={true} />
      <Paragraph>dit zijn mijn favorieten voor de uitreiking van de {config[layout].subject} op {broadcastDate}</Paragraph>
    </div>
    <ul className={styles.screenshotList}>
      {votingsWithShortDescription?.map((category, index) => {
        return <li key={index} className={styles.screenshotListItem}>
          <Paragraph className={styles.screenshotNumber}>{index + 1}.</Paragraph>
          <Paragraph className={styles.screenshotLabel}>{category.shortDescription}</Paragraph>
          <Paragraph className={styles.screenshotVote}>{category.selection}</Paragraph>
        </li>
      })}
    </ul>
  </div>
}

export {
  ShareScreenshot
}
